@import 'design/template/mixin_templates';
@import "design/variables";
@import url('https://fonts.googleapis.com/css?family=Great+Vibes|PT+Sans+Narrow');

/*-----------------------------
    GENERAL
-------------------------------*/
body {
  background-repeat: repeat;
  margin: 0;
  font-family: 'Cabin Condensed', arial, sans-serif;
  font-size: 16px;
}

@include titreBaseSize();

h2, .h2 {
  border-bottom: 4px solid;
  padding: 5px 0;
}

img:not(.img-logo) {
  max-width: 100%;
  height: auto !important;
}

table {
  max-width: 100% !important;
}

iframe {
  max-width: 100% !important;
}

header {
  position: relative;
  z-index: 1050;
}

.protectionpass {
  margin: 25px auto;
}

.form-contact {
  clear: both;
}

.row-fluid {
  max-width: 980px;
  margin: 0 auto;
}

.ie8 .mask {
  transition: all 0.3s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;
}

.ie8 .mask:hover {
  height: 100%;
  width: 100%;
}

.center-img-slider {
  padding: 0;
  margin: 0;
}

.content .bx-wrapper .bx-viewport {
  left: 0;
}

body .bx-wrapper .bx-controls-direction a {
  z-index: 900;
}

.bx-prev-perso, .bx-next-perso {
  border-radius: 5px;
  font-size: 10px;
}

.nav-static-breadcrumb.links_p {
  margin-top: 15px;
}

.content .page p {
  padding-left: 10px;
  padding-right: 10px;
}

#bx-pager img {
  width: 150px;
}

#bx-pager > a {
  display: inline-block;
}

#bx-pager {
  text-align: center;
}

.slide-text {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.pagination li span {
  height: 16px !important;
  position: static !important;
  line-height: 16px;
  width: 0 !important;
}

/*-----------------------------
    ENTETE DE PAGE 
-------------------------------*/
header {
  margin: 0 auto;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  &::after {
    content: '';
    clear: both;
    display: block !important;
  }
}

.ie7 header {
  left: 0;
}

.theme .banniere {
  top: auto;
}

/* live_edit 
    -- permet de position le logo et le slogan
    -- position : relative (obligatoire)
*/
#blocContent {
  height: 37px;
  width: 100%;
}

.menu.navigation_p {
  height: auto;
  margin: 0 auto;
  width: 960px;
  position: relative;
}

.menu.navigation_p::after {
  content: '';
  clear: both;
  display: block;
}

.container-slogan {
  height: 37px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

#headercontent {
  width: 962px;
  position: relative;
  margin: 0 auto;
  height: 111px;
  box-sizing: border-box;
  top: 38px;
}

.slogan_p.slogan {
  z-index: 10;
}

.slogan_p {
  font-size: 22px;
  right: 0;
  top: -38px !important;
  line-height: 1.5;
  margin: 0;
  position: absolute;
  text-align: right;
  width: auto;
}

.logo {
  left: 15px;
  position: absolute;
  top: 0;
  z-index: 1;
  display: inline-block;
}

#headercontent .logo img {
  max-height: 100px;
  max-width: 100%;
}

/* Navigation */

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

nav#nav-principal {
  * {
    box-sizing: border-box;
  }
  > ul > li {
    display: block;
    float: left;
    > a {
      padding: 46px 15px;
      box-sizing: border-box;
      display: block;
    }
    > ul {
      display: none;
      padding-top: 5px;
    }
    &:hover {
      display: block;
      animation-duration: 0.8s;
      animation-name: fadeInFromNone;
      > ul {
        display: block;
        position: absolute;
        width: 100%;
        z-index: 5;
        left: 0;
        animation-duration: 0.2s;
        animation-name: fadeInFromNone;
      }
    }
  }
  li {
    list-style: none;
    clear: none;
    width: auto;
    padding: 0;
    margin: 0;
    position: static;
    &.actif, &:hover {
      box-shadow: inset 0 3px 9px 2px rgba(0, 0, 0, 0.42);
    }
    li {
      float: none;
      display: inline-block;
      height: 100%;
      //margin: 10px 3px;
      text-align: center;
      width: 24%;
      cursor: pointer;
      position: relative;

      padding: 0;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      vertical-align: middle;
      margin: 0 0 .8% .8%;
      > a {
        color: #000;
      }
      a {
        padding: 15px 10px;
      }

      li {
        width: 100%;
        margin: 0;
        text-align: left;
        display: inline-block;
        a {
          line-height: 2px
        }
      }
      &:hover {
        > ul {
          display: block;
        }
      }
      ul {
        z-index: 9999;
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        width: calc(100% + 2px);
        margin-left: -1px;
        li {
          text-align: center;
          margin-top: -1px;
        }
      }
    }
  }

  ul {
    font-size: 0;
    > li {
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;
    width: 100%;
    display: block;
  }
}

nav.secondaire ul {
  a {
    padding: 5px;
    display: block;
  }
  ul {
    display: none !important;
  }
}

/*-----------------------------
    CONTENT
-------------------------------*/
.wrapper {
  width: 100%;
  max-width: 960px;
  margin: 15px auto 0;
}

.content {
  padding: 0 10px 50px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  &.accueil_diaporama_template_5 {
    padding-bottom: 10px;
  }
}

#content_full {
  padding-bottom: 40px;
}

aside.sidebar {
  display: none;
}

/***    SIDEBAR   ***/
#nav-principal ul {
  margin: 0;
  padding: 0;
}

.prevnav a {
  display: inline-block;
  text-decoration: none;
}

.nextt {
  height: 28px;
  position: absolute;
  right: -5%;
  width: 28px;
}

.prevv {
  height: 28px;
  position: absolute;
  left: -5%;
  width: 28px;
}

nav#nav-principal {
  float: right;
  font-weight: bold;
  height: auto;
  font-size: 15px;
}

nav.nav-static-breadcrumb ul li a:hover {
  opacity: 0.9;
}

nav.nav-static-breadcrumb ul li a:hover:after {
  opacity: 0.9
}

nav.nav-static-breadcrumb ul {
  list-style-type: none;
  margin: 0 0 20px;
  padding: 0;
  display: block;
}

nav.nav-static-breadcrumb ul li a {
  float: left;
  margin: 0 5px 0 0;
  padding: 5px 10px;
  position: relative;
}

nav.nav-static-breadcrumb ul li:nth-child(2) a {
  padding: 5px 10px 5px 18px;
}

nav.nav-static-breadcrumb ul li:first-child a {
  margin: 0 10px 0 0;
}

nav.nav-static-breadcrumb ul li:nth-child(2) a:before {
  border-bottom: 14px solid rgba(0, 0, 0, 0);
  border-left-width: 10px;
  border-left-style: solid;
  border-top: 14px solid rgba(0, 0, 0, 0);
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

nav.nav-static-breadcrumb ul li:first-child a:after {
  border-bottom: 14px solid rgba(0, 0, 0, 0);
  border-left-width: 10px;
  border-left-style: solid;
  border-top: 14px solid rgba(0, 0, 0, 0);
  content: "";
  height: 0;
  right: -10px;
  position: absolute;
  top: 0;
  width: 0;
}

nav.nav-static-breadcrumb ul li a {
  text-decoration: none;
  font-size: 14px;

}

.pagination li {
  display: inline-block;
  list-style: none outside none;
  margin-right: 10px;
  position: relative;
}

.page {
  clear: both;
}

#slide {
  position: relative;
}

.prevnav {
  display: block;
  position: absolute;
  text-align: center;
  top: 33%;
  width: 100%;
}

.prevnav {
  clear: both;
}

.pagination li.current span, .pagination li:hover span {
  display: block;
}

.pagination li span {
  display: block;
  height: 95%;
  left: 0;
  position: absolute;
  top: 0;
  width: 219px;
  z-index: 999;
}

.ie7 .pagination li span {
  left: 330px;
}

.pagination a {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.pags.pagination img {
  position: relative;
  vertical-align: middle;
}

.pagination {
  display: block;
  position: relative;
  text-align: center;
  z-index: 10;
  padding: 0;
}

.album-photo {
  padding-top: 10px;
}

.content .page p, footer p {
  font-size: 15px;
}

h2, .h2 {
  line-height: 40px;
}

.page h2,
.page .h2 {
  line-height: 20px;
  margin-bottom: 0;
}

.title_section {
  font-size: 30px;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 0;
  margin-top: 24px;
}

.content #fiche-produit .wrap-description h3,
.content #fiche-produit .wrap-description .h3 {
  margin: 0;
}

.page ul {
  list-style-type: circle;
}

.page p {
  font-size: 12px;
  margin: 5px 0;
}

/***    BOUTIQUE     ***/
.boutique {
  padding: 25px 0 0 20px;
}

.produits-accueil .productImageWrap {
  margin-bottom: 10px;
}

#fiche-produit #boutique {
  overflow: hidden;
  _overflow: visible; /* for IE */
  _zoom: 1; /* for IE */
  margin: -50px 0 0 -48px;
}

.productContentWrap {
  padding: 10px;
}

.produits {
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .prix {
    .prixprod {
      font-size: 15px;
    }
    .oldprix {
      font-size: 13px;
      text-decoration: line-through;
      white-space: nowrap;
    }
  }
  .zoom {
    //background-image: url("../images/imgd612/zoom.png");
    background-repeat: no-repeat;
    bottom: 0;
    display: block;
    position: absolute;
    right: 0;
    //text-indent: -9999px;
    top: 0;
  }
  .produit_etiquette {
    width: 100%;
    height: auto;
    h3, .h3 {
      font-size: 1em;
      padding-bottom: 8px;
    }
  }
  .nomprod {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    padding: 5px 0;
    text-align: center;
    text-transform: uppercase;
  }
  .desc {
    padding: 5px 0 5px;
    font-size: 14px;
    clear: both;
  }
  .prixprod, .description, .details {
    color: inherit !important;
  }
  .attr {
    margin-top: 5px;
  }
  select {
    border-width: 1px;
    border-style: solid;
    margin-right: 0;
    height: 27px;
    width: 35%;
    padding: 3px;
    left: -5px;
    position: relative;
  }
  .remise {
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    position: absolute;
    right: auto;
    left: 10px;
    bottom: 0;
    white-space: nowrap;
    z-index: 100;
    > span {
      position: absolute;
      z-index: 101;
    }
    &:after {
      content: "";
      right: auto;
      left: -10px;
      top: auto;
      bottom: 0;
      position: absolute;
      z-index: -1;
    }
  }
  &.is_etiquette .remise {
    z-index: 1000;
  }
  .addbasket {
    text-align: center;
    cursor: pointer;
    border: 1px solid;
    .icon-cart {
      display: inline-block;
      height: 30px;
      left: 20px;
      position: absolute;
      top: 0;
      width: 30px;
    }
  }
  .note-produit {
    text-align: center;
  }
}

.template_boutique.template_boutique_1 .produits,
.template_boutique.template_boutique_4 .produits {
  .remise {
    top: 15px;
    &::after {
      top: -15px;
      transform: rotate(90deg);
    }
  }
}

.template_boutique_1 .produits {
  .attr {
    margin-left: 0;
  }
  .addbasket.next-select {
    margin-right: 0;
  }
  .addbasket, .hors-stock {
    margin-bottom: 0;
  }
  .block-btn-addbasket {
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0;
    .addbasket {
      margin-top: 5px;
      &:not(.next-select) {
        width: auto;
        max-width: 80%;
      }
    }
  }
}

.template_boutique_3 .produits {
  .nomprod {
    text-align: center;
  }
  .prix {
    text-align: left;
    .oldprix, .prixprod {
      float: none;
      margin: 5px;
    }
    .oldprix {

    }
    .prixprod {

    }
  }
  .attr {
    select {
      float: none;
    }
  }
  .block-btn-addbasket {
    justify-content: left;
  }
}

.template_boutique.template_boutique_4 .produits,
#fiche-produit .template_boutique.template_boutique_4 .produits {
  &.is_etiquette .remise {
    top: 15px;
  }
}

/* Fiche Produit */

#fiche-produit {
  padding-top: 15px;
  .wrap-details h4,
  .wrap-details .h4 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
  }
  .details {
    margin-top: 30px;
  }
  .quantite {
    border-style: solid;
    border-width: 1px;
  }
  .declinaison select {
    border-style: solid;
    border-width: 1px;
    padding: 6px;
    margin-right: 30px;
  }
  .declinaison {
    float: left;
    width: 100%;
  }
  .ajouter-panier {
    padding-top: 17px;

    &.block-addbasket {
      display: inline-block;
      width: auto;

      .addbasket {
        display: inline-block;
      }
    }
  }
  .description {
    clear: both;
    padding-top: 20px;
    padding-bottom: 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .wrap-description {
    .prix {
      border: medium none !important;
    }
    .sociaux {
      border: medium none !important;
    }
    h3, .h3 {
      margin: 30px 0 0;
    }
    h1, .h1 {
      margin-top: 0;
    }
    .brand {
      border: none !important;
    }
  }
  .bloc-quantite {
    .bt-quantity {
      height: auto;
    }
  }
  .remise-produit {
    border-radius: 0;
    height: 25px;
    width: 35px;
    position: absolute;
    left: -5px;
    bottom: 0;
    top: auto;
    &::before {
      content: "";
      display: block;
      height: 0;
      left: 5px;
      position: absolute;
      bottom: 0;
      width: 0;
      z-index: -1;
    }
  }
  .wrap-images {
    .bx-wrapper {
      margin-bottom: 0;
    }
    .triangle-ficheprod {
      left: 13%;
    }
  }
  #savoirplus {
    margin-top: 60px;
  }
}

.template_fiche_produit_1 #fiche-produit .remise-produit {
  border-radius: 0;
  height: 25px;
  width: 35px;
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: auto;
  &::before {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    left: -20px;
    bottom: -35px;
  }
}

#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > .button {
    flex: 0 1 auto;
    margin: 15px 0;
  }

  > .button + .button {
    margin-left: 15px;
  }

}

.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}

.fil-dariane {
  left: 0;
  margin: 5px 0;
  top: -6px;
  a {
    text-decoration: none;
    font-size: 12px;
    padding: 5px;
    .fa.fa-fa.fa-arrow-left {
      padding: 0 5px 0 0;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

/***    ALBUMS PHOTO   ***/

#contentgalerie {
  padding: 30px 0;
  p {
    font-size: 12px;
  }
}

.list-photos {
  overflow: hidden;
  _overflow: visible; /* for IE */
  _zoom: 1; /* for IE */
  width: 100%;
}

.mask {
  transition: all 0.3s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  text-align: center;
  margin: 0;
  z-index: 20;
  overflow: hidden;
}

.lien-mask {
  position: absolute;
  top: 0;
  left: 1px;
  height: 98%;
  width: 100%;
  display: block;
  opacity: 0.4;
  z-index: -50;
  text-indent: -1000px;
}

.list-produits .produit_galerie_background_p .button {
  padding: 10px;
}

.productImageWrap {
  position: relative;
}

.ie10 .remise:after {
  left: -19px;
}

.ie7 .remise-produit:after, .ie8 .remise:after {
  left: -5px;
  top: -7px;
}

.ie7 .content #fiche-produit .remise-produit, .ie8 .content #fiche-produit .remise-produit {
  left: 5px;
  top: 8px;
}

.mask {
  &:hover {
    margin: 0;
    opacity: 1;
    text-align: center;
    width: 100%;
    z-index: 20;
  }
  > .commentaire {
    background: none repeat scroll 0 0 transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-size: 11px;
    font-weight: bold;
    margin: 14px 10px;
    opacity: 1;
    padding: 0 0 5px;
    position: relative;
    z-index: 1;
  }
  > h3, > .h3 {
    margin: 5px 9px 0 0;
    font-size: 10px;
    position: relative;
    z-index: 1;
  }
  p {
    z-index: 1;
    a {
      float: none;
    }
  }
  .addcommentaires {
    float: none;
    padding: 8px 48px 0;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.template_album {
  .galerie {
    .commentP {
      p {
        text-align: center;
      }
    }
  }
}

.ie7 .triangle {
  margin-top: 10px;
  margin-left: 10px;
}

.diaporama {
  text-align: center;
}

/***    LIVRE D'OR     ***/
.livre_dor > h2,
.livre_dor > .h2 {
  display: block;
  margin-bottom: 25px;
}

.message_lo {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 25px;
  h4, .h4 {
    font-size: 14px;
    float: left;
  }
  .web {
    font-size: 12px;
    font-style: italic;
    a {
      text-decoration: underline;
    }
  }
  .note {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    float: right;
    font-size: 19px;
    font-weight: bold;
    height: 25px;
    margin-top: 10px;
    overflow: hidden;

    width: 60px;
  }
  .message {
    clear: both;
    font-size: 13px;
    min-height: 50px;
    padding: 10px;
  }
}

.livre_dor .message_lo .IDLO time {
  float: none;
}

/***    FORMULAIRE D'AJOUT DE MESSAGE SUR LE LIVRE D'OR     ***/

#contact {
  margin: 0 auto;
  width: 98%;
}

.form fieldset {
  border: medium none;
  float: left;
  margin: 0;
}

.form fieldset.last {
  margin-left: 0;
  padding: 0;

  float: right;
}

.form > fieldset {
  border: medium none;
  float: left;
  margin: 0;
  padding-left: 0;
}

.ie10 .form fieldset {
  padding-right: 65px;
}

.ie10 .userdigit {
  float: none;
}

.form fieldset textarea {
  border-style: solid;
  border-width: 1px;
  height: 200px;
  margin-top: 6px;
  width: 600px;
}

.userdigit {
  padding-left: 65px;
  display: block;
  float: right;
}

.form label {
  display: block;
  padding-bottom: 10px;
  color: inherit;
}

.form input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, .3);
  height: 30px;
  //max-width: 352px;
  padding-left: 10px;
}

.form input:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid rgba(0, 0, 0, .5);
}

.form input[type=submit] {
  width: 250px;
  height: 50px;
}

.userdigit .button {
  display: block;
  margin: 50px auto;
  position: relative;
  top: 1px;
}

/***    PAGE DE CONTACT     ***/
.bloc-adresse {
  position: relative;
  margin-top: 10px;
}

.bloc-adresse .adresse {
  font-size: 14px;
}

.adresse {
  bottom: 45px;
  display: block;
  left: -15px;
  margin: 15px;
  position: absolute;
  text-align: center;
  width: 940px;
  z-index: 800;
}

.inmap .background-adr {
  height: 103px;
  left: 0;
  margin-top: -32px;
  opacity: 0.6;
  position: absolute;
  top: 30px;
  width: 940px;
  z-index: -1;
}

.map {
  margin: 0 auto 0 0;
  padding-bottom: 45px;
  padding-top: 30px;
  width: 960px;
}

.adresse a {
  text-decoration: none;
}

.adresse a:hover {
  text-decoration: underline;
}

.adresse2 {
  margin: 0 auto;
}

.userdigit {
  margin-top: 1px;
}

.userdigit > label {
  padding-bottom: 0;
}

/*-----------------------------
    PIED DE PAGE
-------------------------------*/
footer {
  width: 960px;
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer ul li {
  display: inline-block;
  padding: 6px;
}

footer ul li a {
  text-decoration: none;
}

footer ul li ul {
  display: none;
}

footer nav {
  padding: 5px;
}

.mention {
  padding-bottom: 10px;
  padding-top: 10px;
  clear: both;
}

.mention a {
  text-decoration: none;
  font-size: 10px;
}

.mention .links_p .footer_p {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.triangle {
  border-bottom: 6px solid transparent;
  border-left-style: solid;
  border-left-width: 7px;
  border-top: 6px solid transparent;
  height: 0;
  text-align: center;
  width: 0;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
}

/*
    * Style Slider iView du theme
*/
.slide {
  padding: 0 0;
}

.items {
  display: none;
}

.clearfix:before,
.clearfix:after,
.row-fluid:before,
.row-fluid:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after,
.row-fluid:after {
  clear: both;
}

.clearfix,
.row-fluid {
  zoom: 1; /* IE < 8 */
}

.secondaire ul li.menu-page, .secondaire ul li.menu-boutique, .secondaire ul li.menu-album {
  position: relative;
}

.secondaire ul li li {
  float: none;
  display: block;
  white-space: nowrap;
}

.secondaire ul li li li {
  display: none;
}

.secondaire ul li:hover > ul {
  bottom: 29px;
  display: block;
  left: 0;
  position: absolute;
}

#infoscommande input[type=text], #infoscommande textarea {
  box-sizing: border-box;
}

/* Diaporama accueil */
.accueil_diaporama_template {
  .slide .slide-text {
    .content-slide-text > p {
      bottom: 50px;
      left: 0;
      padding-left: 50px;
      margin: 0;
    }
    .content-slide-text > a.slider-see-more {
      margin: 0;
      top: 40px;
      bottom: 20px;
      height: 30px;
      line-height: 30px;
      left: 50px;
      box-sizing: border-box;
      display: inline-block;
      right: auto;
      padding: 0 10px;
      width: auto;
    }
  }
  &.accueil_diaporama_template_5 {
    .slide .slide-text {
      box-sizing: border-box;
      .content-slide-text {
        > a.slider-see-more {
          left: 10px;
        }
        > p {
          bottom: 10px;
        }
      }
    }
    .bx-wrapper {
      .slider-see-more {
        max-width: 90%;
        box-sizing: border-box;
      }
    }
  }
}

/*-----------------------------
    GESTION DES CLASSES OBJETS GENERALE
-------------------------------*/
/*
    * Gestion des photos gerer avec fancybox
    * sur les produits et galerie images
*/
.livre_dor .button {
  margin: 0 0 0 20px;
  float: right;
}

.smiley img {
  margin: 0;
}

.button, .produits .addbasket, .addmsglo, #fiche-produit .addbasket, .produits .hors-stock {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-top: 10px;
  padding: 9px 18px;
  text-decoration: none;
}

.button:hover, .produits .addbasket:hover, .addmsglo:hover {
  transition: all 0.2s linear;
}

.contact_text {
  margin: 0 20px;
  font-size: 14px;
}

body .template_album_3 .list-photos .galerie.sans-texte .bloc-cache-overflow {
  display: none;
}

/* =============================================================================
    Print styles.
    Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
========================================================================== */

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  /* Black prints faster: sanbeiji.com/archives/953 */
  a, a:visited {
    color: #444 !important;
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  /* Don't show links for images, or javascript/internal links */
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  /* css-discuss.incutio.com/wiki/Printing_Tables */
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p, h2, h3, .h2, .h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3, .h2, .h3 {
    page-break-after: avoid;
  }
}

body .wrapper nav.nav-static-breadcrumb li {
  list-style-type: none;
}

/* Template panier */
.template-panier {
  border: none !important;
}

body.cmonsite-panier-2 .template-panier.template-panier-2 {
  border: none !important;
  #panier #header-panier {
    padding: 46px 15px;
    box-sizing: border-box;
    display: block;
    height: auto;
    .count-live {
      top: auto;
      margin-top: 9px;
      margin-left: 5px;
    }
  }
}

body.cmonsite-panier-3 #blocContent .template-panier.template-panier-3 {
  top: -38px !important;
  max-height: 37px !important;
  .bloc-connexion-panier {
    #panier {
      height: 37px;
      #header-panier {
        padding: 10px;
      }
    }
    .connexion p {
      margin: 3px 0;
    }
  }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  .side-tabs.etapes,
  div#resume .block-recap-panier,
  div#resume div.next-step,
  div#information #connexion-inscription,
  div#information div.next-step,
  #infoscommande,
  div#finish .block-info-payment,
  div#finish div.next-step,
  div#resume h4,
  div#finish h4,
  .message-commande {
    width: 95%;
  }

  .logo-carrier-none {
    width: auto;
  }

  h4 {
    margin: 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

.cmonsite-fluxpanier-2 #main-conteneur.page-panier_commande {
  #wrapper .content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/
@media #{$large-and-down} {
  #headercontent, .menu.navigation_p {
    width: 100% !important;
    box-sizing: border-box;
    max-width: 960px;
  }
  .logo img {
    max-width: 100% !important;
    height: auto !important;
  }
  #nav-principal > ul > li > a {
    padding: 40px 13px;
  }

  footer {
    width: 100%;
    padding: 0;
    margin: 10px 0
  }
}

@media #{$medium-and-down} {
  #headercontent, .menu.navigation_p, .wrapper {
    width: $small-screen !important;
  }
  .cmonsite-panier-3 {
    #headercontent {
      width: 100% !important;
    }
    .template-panier:not(.template-panier-1) .count-live {
      top: 15px;
    }
  }

  .content img {
    height: auto;
  }
  .list-produits, #fiche-produit #boutique {
    margin: 0;
  }
  .productImageWrap {
    float: none;
    margin: 0 auto;
  }
  .is_etiquette .remise {
    right: initial !important;
  }
  .content #fiche-produit .wrap-images .grande-image > a, .content #fiche-produit .wrap-images .grande-image {
    height: auto;
  }
  #fiche-produit .wrap-images img {
    margin-bottom: 5px;
  }
  .ui-datepicker {
    width: 90% !important;
    font-size: .8em;
    left: 4% !important;
  }
  .ui-datepicker td {
    display: table-cell;
    width: auto;
  }
  #block-flottant-search {
    display: none;
  }

  #nav-principal > ul > li > a {
    padding: 34px 12px;
  }
  #fiche-produit .wrap-description .declinaison, #fiche-produit .wrap-description .ajouter-panier {
    margin: 10px 0;
    padding-top: 0;
  }
  #fiche-produit .declinaison {
    float: none;
  }
}

@media #{$medium-and-down} {
  /* Template Responsive Panier */
  .cmonsite-panier-2 {
    #blocContent {
      height: auto;
    }
    #headercontent {
      top: 0;
      height: 100% !important;
    }
    .template-panier.template-panier-2 {
      height: 50px !important;
      top: -50px;
      right: 0;
      #panier #header-panier {
        padding: 0 !important;
        line-height: 50px !important;
      }
    }
  }

  #blocContent {
    z-index: 1002;
    height: 100%;
  }
  .menu.navigation_p {
    width: 100% !important;
    float: none;
  }
  #headercontent {
    height: auto;
    text-align: center;
    top: 0;
  }
  #minisite-sidebar {
    display: none;
  }
  .slogan {
    height: auto !important;
    position: static !important;
    width: 100% !important;
    text-align: center !important;
    font-size: 15px !important;
  }
  .logo {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    position: static !important;
    display: inline-block;
    width: auto !important;
    height: auto !important;
    img {
      width: auto !important;
      height: auto !important;
      max-height: 75px !important;
      max-width: 100% !important;
    }
  }
  body.cmonsite-panier-3 #blocContent .template-panier.template-panier-3 {
    top: 0 !important;
    margin-bottom: 0 !important;
    max-height: 100% !important;
  }
  #nav-principal > ul > li > a {
    padding: 32px 12px;
  }

  #nav-principal > ul > li > a {
    padding: 29px 12px;
  }

  .content .form-newsletter div:last-child {
    width: 100%;
  }
  .content .form-newsletter input[type=text] {
    width: 100%;
    box-sizing: border-box;
  }
  .content .form-newsletter input[type=submit] {
    display: block;
    margin: 5px auto;
  }
  .search-bar .search-bar-icon {
    width: 8%;
  }
  .message_lo h4,
  .message_lo .h4 {
    width: 82%;
    word-wrap: break-word;
  }
  .livre_dor .row .col.s3 {
    width: 25%;
  }
  .slogan {
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    text-align: center !important;
    font-size: 17px !important;
  }
}

@media #{$medium-and-down} {
  /* Nav mobile */
  #headercontent, .menu.navigation_p {
    z-index: 1000;
  }
  #headercontent {
    height: auto;
  }
  .logo {
    width: 13%;
    text-align: center;
  }

  .template_fiche_produit_2 #fiche-produit .wrap-images .grande-image #sliderImagePreviews {
    height: 110px;
  }
  .template_fiche_produit_2 #fiche-produit .wrap-images .triangle-ficheprod {
    bottom: 110px !important;
    left: 0 !important;
  }
  .template_fiche_produit_2 #fiche-produit .wrap-description .container-etiquette {
    left: 0 !important;
  }
  .accueil_boutique_template_2 {
    .productImageWrap {
      float: none;
    }
    .nomprod, .prix {
      width: 45%;
      float: right;
    }
  }

  body .wrapper.accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more, body .wrapper.accueil_diaporama_template .slide .slide-text .content-slide-text > a,
  body .wrapper.accueil_diaporama_template .slide .slide-text.nocomment .content-slide-text > a.slider-see-more, body .wrapper.accueil_diaporama_template .slide .slide-text.nocomment .content-slide-text > a,
  body .accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more, body .accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more {
    height: auto;
    line-height: normal;
    padding: 10px 10px;
    top: 20px;
    bottom: auto;
    left: 15px;
    right: auto;
    max-width: calc(100% - 130px);
  }
  body .accueil_diaporama_template.accueil_diaporama_template_5 .slide .slide-text .content-slide-text > a.slider-see-more, body .accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more {
    left: auto;
    right: 10px;
  }

  .cmonsite-panier-2 nav#nav-principal {
    float: none;
    padding-right: 60px;
  }
  .cmonsite-panier-2 .template-panier.template-panier-2 {
    height: 0;
    top: 55px;
    position: absolute;
    #header-panier {
      top: -52px;
      right: 0;
    }
    #paniercontent {
      left: auto;
      right: 0;
      max-width: 100%;
      padding-top: 0;
    }
  }
  nav#nav-principal {
    text-align: left;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    width: $small-screen;
    margin: 0 auto;
    float: none;
    * {
      box-sizing: border-box;
    }
    > ul {
      display: none;
    }
  }
  header nav a {
    padding: 7px 24px;
  }
  .menu_burger {
    cursor: pointer;
    background-color: #fff;
    display: block;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  .produits select {
    position: relative;
  }

  .menu_burger::before {
    font-family: FontAwesome, sans-serif;
    content: "\f0c9";
    margin-right: 5px;
  }
  header nav > ul {
    margin-left: -1px;
    position: relative !important;
    width: 100%;
    left: 0;
    right: 0;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  nav#nav-principal > ul > li {
    float: none;
    margin-right: 0;
    display: block;
    position: relative;
    border-bottom: 1px solid #ccc;
    &.actif, &:hover, &.sousmenu_ouvert {
      box-shadow: inset 0 3px 9px 2px rgba(0, 0, 0, 0.42);
    }
  }
  nav#nav-principal > ul > li:hover, #nav-principal .navigation_button_p:hover > ul {
    animation: none;
    position: relative;
  }
  nav#nav-principal > ul > li a {
    padding: 0 15px !important;
    height: 40px !important;
    box-sizing: border-box;
    line-height: 40px !important;
  }
  nav .navigation_button_p {
    margin-bottom: 0;
  }
  .navigation_button_p::before, .navigation_button_p::after {
    border: none;
  }
  .dropDown {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    display: block;
    width: 40px;
    cursor: pointer;
    text-align: center;
    font-size: 1.3rem;
  }
  nav#nav-principal li ul {
    display: block;
    position: relative !important;
    width: 100%;
    text-align: left;
    border: none;
    box-shadow: inset 0 3px 9px 2px rgba(0, 0, 0, 0.42);
    z-index: 100;
    margin-top: 1px;
  }
  #nav-principal .navigation_button_p:hover > ul {
    border-bottom: none !important;
  }
  nav#nav-principal li ul li {
    padding-left: 15px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid #ccc;
  }
  nav#nav-principal li ul li a {
    display: block;
    padding: 9px;
  }

  .title_section {
    margin-top: 10px;
  }

  @import 'design/helper/responsive/mobile/mobile';

  .content #paniercommandecontent table td {
    display: table-cell;
    width: auto !important;
  }
  .button, .produits .addbasket, .addmsglo {
    padding: 6px 15px;
  }
  .search-bar .search-bar-icon {
    width: 12%;
  }
  .content #fiche-produit .wrap-images, .content #fiche-produit .wrap-description {
    float: none;
  }
  .content #fiche-produit .wrap-images {
    width: 250px;
    margin: 0 auto 10px;
  }

  #fiche-produit .zoomPup, #fiche-produit .zoomWindow {
    display: none !important;
  }
  #fiche-produit .zoomPad {
    cursor: default;
  }
  #fiche-produit .wrap-images img {
    opacity: 1 !important;
  }
  .content #fiche-produit .wrap-description {
    width: 100%;
  }
  #fiche-produit .container-comment > div {
    padding: 20px 5px !important;
  }
  .content #fiche-produit .input-form-moyen {
    width: 40%;
  }
  .content #fiche-produit .form-group {
    padding: 0 1%;
  }
  #fiche-produit form select {
    margin: 2px 0;
  }

  .mask .addcommentaires {
    padding: 0;
  }
  .message_lo {
    margin: 20px;
  }
  .message_lo h4,
  .message_lo .h4 {
    width: 80%;
  }
  .message_lo .note {
    margin-top: 16px;
  }
  #infoscommande fieldset {
    padding-left: 30px;
  }

  nav#nav-principal {
    .sousmenu_ouvert > ul {
      display: block;
    }
    li li ul li {
      text-align: left;
    }
  }
}

@media #{$small-and-down}{

  #headercontent, .slogan, #blocContent, .wrapper, nav#nav-principal {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  #minisite-sidebar {
    display: none;
  }

  .form-group input, .form-group textarea {
    width: 100% !important;
  }

  .produits.is_etiquette .remise {
    left: 10px;
  }
  .productImageWrap, .produits img, .mask, .produits .produit_etiquetten, .produits p.nomprod, .produits p.prix, .produits p.attr {
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
    position: relative;
    display: block;
  }
  .template_boutique_1 .produits .block-btn-addbasket {
    justify-content: center;
    align-items: center;
    .addbasket:not(.next-select) {
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }
  }
  .produit_etiquette h3,
  .produit_etiquette .h3 {
    font-size: .8em;
  }
  .is_etiquette .remise {
    left: 36px;
  }
  .content #fiche-produit .input-form-moyen {
    width: 50%;
  }
  .message_lo {
    margin: 12px;
  }
  .message_lo .note {
    font-size: 17px;
    height: 21px;
    width: 42px;
    margin-top: 20px;
  }
  body #panier {
    width: 80%;
    right: 7%;
  }
  #panier .button, #paniercmd .button {
    font-size: 1em;
    padding: 5px 10px;
  }
  #paniercmd .pull-right {
    float: none !important;
  }
  #paniercmd #totalbloc {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 360px) {
  .produits .produit_etiquette h3,
  .produits .produit_etiquette .h3 {
    font-size: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #fiche-produit .addbasket {
    padding: 5px 10px;
  }
  .search-bar .search-bar-icon {
    width: 12%;
  }
  .addmsglo {
    font-size: .8em;
  }
  .content #fiche-produit .wrap-images {
    width: 80%;
  }
  .formulaires_perso input[type=text].hasDatepicker {
    margin-bottom: 5px;
  }
  #totalbloc {
    width: 100%;
    box-sizing: border-box;
  }
  .ui-datepicker {
    width: 98% !important;
    font-size: .75em;
    left: 0 !important;
  }
  #infoscommande fieldset {
    padding-left: 20px;
  }
}

@media screen and (max-width: 320px) {
  .produits .addbasket {
    font-size: .7em;
  }
  .produits .produit_etiquette {
    height: 27px;
  }
  .produits .produit_etiquette h3,
  .produits .produit_etiquette .h3 {
    font-size: .95em;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

/********** TEMPLATES **********/

.template_fiche_produit {
  &.template_fiche_produit_1 #fiche-produit {
    .ajouter-panier {
      .triangle-ficheprod {
        display: none;
      }
    }
  }
  &.template_fiche_produit_2 #fiche-produit {
    .wrap-images .triangle-ficheprod {
      top: auto;
      bottom: 0;
      right: auto;
      left: 0;
      .remise-produit {
        line-height: 50px;
        font-size: 14px;
      }
    }
    .noteGlobale .moyenne_etoiles {
      display: block;
      width: 100%;
    }
  }

  &.template_fiche_produit_3 #fiche-produit{
    .bloc-quantite{
      margin-bottom: 14px;
    }
  }
}

.produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .produits .remise,
.produits-accueil.template_boutique_accueil.accueil_boutique_template_2 .produits .remise {
  margin-left: 10px;
}

.template_livreor_1 .list-messages .message_lo h4,
.template_livreor_1 .list-messages .message_lo .h4 {
  width: auto;
  padding: 0;
}

#fiche-produit .wrap-images .grande-image {
  height: auto;
}

.template_fiche_produit #fiche-produit .remise-produit {
  position: absolute !important; //Passe au-dessus d'un css dans les balises
}

.accueil_boutique_template_2 {
  .productImageWrap {
    float: none;
  }
  .nomprod {
    width: 100%;
    float: none;
    clear: both;
  }
  .attr {
    position: relative;
    width: 100%;
    select {
      left: 0;
      width: 100%;
    }
  }
  .prix {
    clear: both;
  }
}

.cmonsite-ficheproduit.cmonsite-template #bloc-fil-title {
  h2, .h2, h1, .h1 {
    display: none;
  }
}

@media #{$small-and-down} {
  .template_boutique.template_boutique_5 .produits .attr select,
  #fiche-produit .template_boutique.template_boutique_5 .produits .attr select {
    left: 0;
  }
}